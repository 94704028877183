import React from 'react'
import cn from 'classnames'
import { inject, observer } from 'mobx-react'

import { labelsStore   } from 'sdc-i18n-stores'
import { MarkdownPanel } from 'sdc-markdown-viewer'

import { link          } from './md-link'

export const ZettelText = inject('zettelStore')(observer(({zettelStore,zettel,className='',...props}) =>
  <div {...props} className={cn(
    'zettel-text',
    className,
    zettel.hidden && 'hidden',
  )}>
    {zettel?.text && <MarkdownPanel text={zettel?.text} link={link} onClick={zettelStore.handleClick} />}
  </div>
))

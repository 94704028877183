import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { ContentApi    } from 'sdc-cms-client'
import { UpdatingStore } from 'sdc-cms-writing'
import { editingMode   } from 'sdc-cms-writing'
import { update        } from 'sdc-mobx-stores'

import { ui            } from '../design'

import { awsDataToEntry } from 'sdc-data-models'

import api from './api'

const typeID = '9DojQIv7cnHzbzAWEZ6kolgX0BnrkEM6'

class KastenStore extends UpdatingStore {

  @observable kastenByID = {}

  @observable recently = []

  reloading = null

  constructor(options) {
    super({
      ...options,
      typeID,
      name      : 'kasten',
    })
    this.clearViewing = false
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      } else {
        this.clearData()
      }
    })

    this.createToEntry = awsDataToEntry('createKasten')
    this.updateToEntry = awsDataToEntry('updateKasten')

    subscribe('network-changed', speed => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      }
    })

    subscribe('kasten-entry-created',  this.kastenCreated)
    subscribe('kasten-entry-updated',  this.kastenUpdated)
    subscribe('kasten-entry-selected', this.kastenSelected)
  }

  setEditingMode = event => {
    editingMode.setMode('edit')
  }
  setViewingMode = event => {
    editingMode.setMode('view')
  }

  reload = () => {
    this.list({
      callback : this.parseAWS
    })()
  }

  subscribeToAWS = () => {
    if (this.subscription) {
      try {
        this.subscription.unsubscribe()
      } catch(error) {
        console.error(error)
      }
    }
    const owner = 'Google_'+userStore.user.id
    this.subscription = API.graphql(graphqlOperation(subscriptions.onUpdateKasten, { owner })).subscribe({
      next  : update => this.onUpdateKasten(update.value?.data?.onUpdateKasten),
      error : error  => console.error(error),
    })
  }

  @action
  clearData = () => {
    this.kastenByID = {}
    this.dataList   = []
  }

  parseAWS = deferred => action(data => {
    if (data?.listKastens?.items) {
      const payload = data.listKastens.items

      this.kastenByID = _.keyBy(payload, 'id')
      this.dataList   = _.orderBy(payload, 'name')

      this.updateRecently()
      if (this.recently.length > 0) {
        this.select(this.recently[0])()
      }
    }
    deferred.resolve(this.dataList)
  })

  @action
  updateRecently = () => {
    const recently = _.sortBy(this.dataList, z => z?.lastUsed || z?.createdAt)
    this.recently  = recently.slice(-10).reverse()
  }

  kastenSelected = kasten => {
    if (kasten) {
      const now = Math.floor(new Date().valueOf() / 1000)
      if (!kasten?.lastUsed || kasten.lastUsed + 10 < now) {
        setTimeout(this.setLastUsed(kasten,now), 50)
      }
      ui.switchTo(kasten.theme)()
      this.setViewingMode()
    }
  }

  kastenUpdated = kasten => {
    if (kasten) {
      this.patch(kasten)
      ui.switchTo(kasten.theme)()
    }
  }

  updateTheme = theme => {
    this.patch({
      id : this.selected.id,
      theme,
    })
  }

  setLastUsed = (kasten,ts) => action(() => {
    if ((kasten.lastUsed || 0) + 10 < ts) {
      this.updateEntryField(this.selected,'lastUsed',ts)
    }
  })

  patch = delta => {
    this.api.patch(delta).then(response => {
      this.onUpdateKasten(response?.data?.updateKasten)
    }).catch(error => {
      console.warn('update failed', error)
    })
  }

  @action
  onUpdateKasten = kasten => {
    if (kasten) {
      this.dataList = update(this.dataList)(kasten)
      if (this.selected.id === kasten.id) {
        this.selected = kasten
        ui.switchTo(this.selected.theme)()
      }
      this.updateRecently()
      if (this.recently.length > 0 && this.selected?.id !== this.recently[0].id) {
        this.select(this.recently[0])()
      }
    }
  }

}

export default ({...options}) => new KastenStore({...options,api:api()})

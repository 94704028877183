import React from 'react'
import cn from 'classnames'

import { Draggable  } from 'react-beautiful-dnd'

import { ZettelName } from './name'

export const zettelToLink = zettelStore => zettel => <div className="pinned-result" key={zettel.id} onClick={zettelStore.select(zettel)}>
  <i className="fa fa-2x fa-thumb-tack pull-left drag-handle" />
  <ZettelName zettel={zettel} />
</div>

export const zettelToDraggable = (zettelStore,tag='') => (zettel,index) =>
<Draggable key={zettel.id || '?'} draggableId={tag+'-'+zettel.id} index={index}>
    {(provided,snapshot) => <div className={cn('search-result', zettel.hidden && 'hidden')}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
    >
      <i          className={'fa fa-2x fa-arrows pull-left priority-'+(zettel?.values?.priority || 0)} />
      <ZettelName className="zettel-link" onClick={zettelStore.select(zettel)} zettel={zettel} />
    </div>}
</Draggable>

export const zettelToPlainDraggable = (zettelStore,tag='') => (zettel,index) =>
<div key={zettel.id || '?'} draggable onDragStart={e => e.dataTransfer.setData("text/plain", zettel.id)} unselectable="on" className={cn('search-result', zettel.hidden && 'hidden')}>
  <i          className={'fa fa-2x fa-arrows pull-left priority-'+(zettel?.values?.priority || 0)} />
  <ZettelName className="zettel-link" onClick={zettelStore.select(zettel)} zettel={zettel} />
</div>

export const nonPending = id => id

export const pos2grid = (id,i=0) => ({
  x : 3 * (i % 4),
  y : 2 * Math.floor(i / 4),
  w : 3,
  h : 2,
  i : id,
})

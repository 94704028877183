import   React      from 'react'
import   cn         from 'classnames'
import { inject, observer } from 'mobx-react'

import { Button   } from 'sdc-react-basics'

import { ui       } from '../design'

export const ThemeButton = inject('kastenStore')(observer(({kastenStore,shade,theme}) => {

  return <Button
    className={cn('theme-selector',shade+theme,
      (shade+theme) !== ui.theme && 'inactive',
      (shade+theme) === ui.theme && 'selected',
    )}
    title={'kasten.theme.'+shade+theme}
    onClick={() => kastenStore.updateTheme(shade+theme)}
  >Text</Button>
}))

import { action, autorun, observable, extendObservable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import api from './api'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { requiredParam } from 'sdc-utilities'
import * as store        from 'sdc-mobx-stores'

import { ContentApi    } from 'sdc-cms-client'
import { editingMode, EDITING_MODE }  from 'sdc-cms-writing'
import { update        } from 'sdc-mobx-stores'

import { awsDataToEntry } from 'sdc-data-models'

import { AmplifyStore   } from '../amplify/store'

import { ui            } from '../design'

import { nonPending, pos2grid } from './helpers'

const typeID = 'CFJRuSzk45MuggBujldNlYYGTdNZ7kVC'

const reorder = (list,startIndex,endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex,1)
  result.splice(endIndex,0,removed)
  return result
}

class ZettelStore extends AmplifyStore {

  @observable zettelByID    = {}
  @observable zettelByIndex = {}

  @observable suche    = ''
  @observable gepinnt  = []
  @observable filtered = []
  @observable verwaist = []
  @observable recently = []
  @observable showHidden = false

  refZettel = {}
  refField  = null
  refTrack  = false

  kastenID = null

  constructor({
    stateStore,
    kastenStore = requiredParam('kastenStore'),
    ...options
  }) {
    super({
      ...options,
      typeID,
      name : 'zettel',
    })
    this.editingStore.namedStores.prev = this
    this.editingStore.namedStores.next = this
    this.editingStore.namedStores.refs = this
    this.clearViewing = false
    this.kastenStore  = kastenStore
    autorun(() => {
      if (userStore.user.id) {
        this.subscribeToAWS()
      } else {
        this.clearData()
      }
    })
    autorun(() => {
      if (kastenStore.selected.id && this.kastenID !== kastenStore.selected.id) {
        this.kastenID = kastenStore.selected.id
        this.reload()
      }
    })
    autorun(() => {
      if (this.recently.length > 0 && editingMode.isViewMode.get() && !this.selected.id) {
        if (window.location.pathname === '/zettel') {
          this.select(this.recently[0])()
        }
      }
    })

    this.createToEntry = awsDataToEntry('createZettel')
    this.updateToEntry = awsDataToEntry('updateZettel')

    subscribe('network-changed', speed => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      }
    })

    subscribe('zettel-entry-selected', this.zettelSelected)
    subscribe('zettel-entry-updated',  this.zettelUpdated)

    subscribe(EDITING_MODE, this.processEditingMode)
  }

  subscribeToAWS = () => {
    //this.subscribeTo('onUpdateZettel')
  }

  processEditingMode = mode => {
    if (mode !== 'edit') return;
    if (this.editingStore.selected.id !== this.selected.id) return;

    this.editingStore.setBuffers(this.selected)
  }

  selectMRU = () => {
    if (this.recently.length > 0 && editingMode.isViewMode.get() && !this.selected.id) {
      this.select(this.recently[0])()
    }
  }

  injectIndex = text => {
    let temp = text

    temp = this.replaceASINs(temp)
    temp = this.replaceLinks(temp)

    return temp
  }

  replaceASINs = text => {
    let temp = text

    const regex = /\[\[\[([^\]]+)\]\]\]/g
    const matches = text.match(regex)
    if (matches) {
      matches.forEach(pattern => {
        const [asin,cover] = pattern.replace(/[\[\]]/g,'').split('/')
        temp = temp.replace(pattern,`<a target="_blank" rel="noopener noreferrer" href="https://www.amazon.de/dp/${asin}?tag=zettelkasten-21" class="amazon-link"><img src="https://images-eu.ssl-images-amazon.com/images/I/${cover}._SL160_.jpg" /></a>`)
      })
    }

    return temp
  }

  replaceLinks = text => {
    let temp = text

    const regex = /\[\[([^\]]+)\]\]/g
    const matches = text.match(regex)
    if (matches) {
      matches.forEach(pattern => {
        const index = pattern.replace(/[\[\]]/g,'')
        const target = this.zettelByIndex[index]
        if (target?.id) {
          temp = temp.replace(pattern,`[${index}](zettel?${target.id})`)
        } else {
          temp = temp.replace(pattern,`<span class="broken-link">${index}</span>`)
        }
      })
    }

    return temp
  }

  handleClick = e => {
    if (e?.target?.nodeName === 'IMG') return;

    const id = e?.target?.getAttribute('data-id')
    if (id) {
      const zettel = this.zettelByID[id]
      if (zettel) {
        e.preventDefault()
        this.select(zettel)()
        return;
      }
    }

    const href = e?.target?.href
    if (href) {
      console.log(href)
      const ids = href.match(/\d+$/)
      console.log(ids)
      if (ids?.length) {
        const zettel = this.zettelByID[parseInt(ids[0])]
        if (zettel) {
          e.preventDefault()
          this.select(zettel)()
        }
      }
    } else {
      e.preventDefault()
      editingMode.setMode('edit')()
    }
  }

  @action
  clearData = () => {
    this.dataList   = []

    this.zettelByID = {}

    this.suche    = ''
    this.gepinnt  = []
    this.filtered = []
    this.verwaist = []
    this.recently = []
  }

  @action
  clearSearch = e => {
    this.suche = ''
  }

  @action
  createZettel = e => {
    const selected = this.editingStore.selectedText || ''
    const matches = /(^\s*)(.*?)(\s*$)/.exec(selected)
    const lead     = matches[1]
    const linkText = matches[2]
    const tail     = matches[3]

    if (linkText) {
      const text = this.selected.text
      const updated = text.substring(0,this.editingStore.selectionStart) +lead+'[['+ linkText +']]'+tail+ text.substring(this.editingStore.selectionEnd)
      console.log(updated)
      this.updateEntryField(this.selected,'text',updated)
    }

    editingMode.setMode('edit')()
    this.create({
      kasten : this.kastenStore.selected.id,
      name   : linkText || null,
    })()
  }

  createFolgeZettel = e => {
    this.refZettel = this.selected
    this.refField  = 'next'
    this.refTrack  = true

    const linkText = (this.editingStore.selectedText || '').trim()
    editingMode.setMode('edit')()
    this.create({
      kasten : this.kastenStore.selected.id,
      name   : linkText || null,
    })()
  }

  createUnterZettel = e => {
    this.refZettel = this.selected
    this.refField  = 'grid'

    const linkText = (this.editingStore.selectedText || '').trim()
    editingMode.setMode('edit')()
    this.create({
      kasten : this.kastenStore.selected.id,
      name   : linkText || null,
    })()
  }

  reload = () => {
    // this.list({
    //   params : {
    //     field  : 'kasten',
    //     linkTo : this.kastenStore.selected.id,
    //     scope  : 'kasten',
    //     parent : this.kastenStore.selected.id,
    //   },
    //   callback : this.parseZettel,
    // })()
    const query = {
      filter: {
        kasten : {
          eq : ''+this.kastenStore.selected.id
        }
      },
      limit: 1000
    }
    API.graphql(graphqlOperation(queries.listZettels, query)).then(
      this.parseAWS
    ).catch(error => {
      console.error(error.errors?.[0]?.message || error)
    })
  }

  parseZettel = deferred => action(payload => {
    this.setEntries(deferred)(payload)
    this.refreshData()
  })

  @action
  parseAWS = response => {
    const payload = response?.data?.listZettels?.items
    if (payload) {
      this.dataList = payload
      this.refreshData()
      if (this.recently.length > 0)
        this.select(this.recently[0])()
    }
  }

  @action
  filter = e => {
    this.suche = e.target.value
    this.updateFiltered()
  }

  @action
  updateFiltered = () => {
    let filtered = []
    if (this.suche.length > 1) {
      filtered = _.filter(this.dataList, z => (z.name || '').toLowerCase().indexOf(this.suche.toLowerCase()) >= 0)
    }
    this.filtered = _.orderBy(filtered, ['priority','name'])
  }

  parents = zettel => _.filter(this.dataList, z => (z.grid || z.refs || []).find(cand => zettel.id === cand?.i || zettel.id === cand))

  refreshData = () => {
    this.buildIndex()
    this.updateFiltered()
    this.updateVerwaist()
    this.updateGepinnt()
    this.updateRecently()
  }

  @action
  updateIndex = () => {
    this.zettelByID[this.selected.id] = this.selected
  }

  @action
  buildIndex = () => {
    this.zettelByID    = _.keyBy(this.dataList, 'id')
    this.zettelByIndex = _.keyBy(this.dataList, z => z?.index || z?.name || z.id)
  }

  @action
  updateVerwaist = () => {
    const verwaist = _.filter(this.dataList, z =>
      !z.priority &&
      _.find(this.dataList, zettel => (zettel.grid || []).find(cand => cand.i === z.id)) === undefined &&
      _.find(this.dataList, zettel => (zettel.refs || []).indexOf(z.id) >= 0) === undefined &&
      _.find(this.dataList, zettel => (zettel.next || []).indexOf(z.id) >= 0) === undefined)
    this.verwaist  = _.orderBy(verwaist, ['priority','name'])
  }

  @action
  updateGepinnt = () => {
    const gepinnt = _.filter(this.dataList,'priority')
    this.gepinnt  = _.orderBy(gepinnt, ['priority','name'])
  }

  @action
  updateRecently = () => {
    const recently = _.sortBy(this.dataList, z => z?.priority ? -1 : (z?.lastViewed || z?.createdAt))
    this.recently  = recently.slice(-10).reverse()
  }

  unlink = (field,id) => action(e => {

    const items = toJS(this.selected[field] || [])
    const index = items.indexOf(id)

    if (index >= 0) {
      items.splice(index,1)

      this.editingStore.updateFieldWith(
        this.typesStore.fields[field],
        undefined, items, true
      )
    }

    if (field === 'refs') {

      const items = toJS(this.selected.grid || [])
      const item  = items.find(i => i.i === id)
      const index = items.indexOf(item)

      if (index >= 0) {
        console.log(`deleting item at ${index}`)
        items.splice(index,1)

        this.editingStore.updateFieldWith(
          this.typesStore.fields.grid,
          undefined, items, true
        )
      }
    }

  })

  afterCreate = entry => {
    this.setSelected(entry)
    this.typesStore.selectType(this.typeID)
    const field = this.typesStore.fields.kasten
    this.editingStore.setBuffers(entry)
    // this.editingStore.assign(field)({
    //   value: this.kastenStore.selected.id
    // })
    this.updateKastenSize()

//    this.zettelByID[entry.id] = entry

    if (this.refField && entry.id) {
      const value = Array.from(this.refZettel[this.refField] || []).filter(nonPending)
      if (this.refField === 'grid')
        value.push(pos2grid(entry.id,value.length))
      else
        value.push(entry.id)
      this.updateEntryField(this.refZettel,this.refField,value)

      if (this.refTrack) {
        const field = this.typesStore.fields.prev
        // this.editingStore.assign(field)([{
        //   value : this.refZettel.id
        // }])
        const parents = this.parents(this.refZettel)
        parents.forEach(parent => {
          const value = Array.from(parent.refs || [])
          value.push(entry.id)
          this.updateEntryField(parent,'refs',value)
        })
      }

      this.refField = null
      this.refTrack = false
    }
  }

  @action
  updateKastenSize = () => {
    this.kastenStore.updateEntryField(this.kastenStore.selected,'size',this.dataList.length)
  }

  zettelSelected = zettel => {
    if (zettel) {
      const now = Math.floor(new Date().valueOf() / 1000)
      if (!zettel?.lastViewed || zettel.lastViewed + 10 < now) {
        setTimeout(this.setLastViewed(now), 100)
      }
    }
  }

  setLastViewed = ts => action(() => {
    if (!this.selected?.lastViewed || this.selected.lastViewed + 10 < ts) {
      this.updateEntryField(this.selected,'lastViewed',ts)
    }
  })

  @action
  zettelUpdated = zettel => {
    if (zettel) {
      this.editingStore.snapshot = extendObservable({}, toJS(zettel))
      this.refreshData()
    }
  }

  @action
  cyclePriority = event => {
    const priority  = ((this.selected?.priority || 0) + 3) % 4

    this.updateEntryField(this.selected,'priority',priority)
  }

  @action
  toggleVisibility = event => {
    this.updateEntryField(this.selected,'hidden',!this.selected.hidden)
  }

  @action
  toggleVisibilityFilter = event => {
    this.showHidden = !this.showHidden
  }

  onDragEnd = result => {

    const { source, destination } = result
    if (!destination) return

    const field = destination.droppableId
    if (field === 'verwaist') return

    if (source.droppableId === field) {

      const items = reorder(
        this.selected[field],
        result.source.index,
        result.destination.index
      )
      this.updateEntryField(this.selected,field,items)

    } else {

      const zettel = this[source.droppableId][source.index]
      const items  = Array.from(this.selected[field] || [])
      items.splice(destination.index,0,zettel.id)

      this.updateEntryField(this.selected,field,items)

    }

  }

  @action
  patchBeforeUpdate = zettel => {
    zettel.lastViewed = Math.floor(new Date().valueOf() / 1000)
  }

  @action
  onUpdateZettel = zettel => {
    if (zettel) {
      this.dataList = update(this.dataList)(zettel)
      if (this.selected.id === zettel.id) {
        this.selected = zettel
        if (editingMode.isViewMode.get()) {
          this.editingStore.setSelected(this.selected)
          this.editingStore.setBuffers(this.selected)
        }
      }
      this.updateRecently()
      if (this.recently.length > 0 && !this.selected?.priority && this.selected?.id !== this.recently[0].id) {
        this.select(this.recently[0])()
      }
    }
  }

  @action
  layout = data => {
    const grid = toJS(this.selected.grid || this.selected.refs.map((id,i) => ({
      i: id,
      x: i,
      y: 0,
      w: 1,
      h: 1
    })))
    Object.keys(data).forEach(key => (data[key] === undefined || data[key] === false) && delete data[key])
    delete data.moved

    const item   = grid.find(i => i.i === data.i)
    const rawPos = grid.indexOf(item)
    if (rawPos < 0) {
      grid.push(data)
    } else {
      grid[rawPos] = data
    }

    try {
      this.updateEntryField(this.selected,'grid',grid)
    } catch (e) {
      console.info(e)
    }
  }

  @action
  drop = item => {
    Object.keys(item).forEach(key => (item[key] === undefined || item[key] === false) && delete item[key])
    delete item.isDraggable

    const grid = toJS(this.selected.grid) || []
    grid.push(item)

    try {
      this.updateEntryField(this.selected,'grid',grid)
    } catch (e) {
      console.info(e)
    }
  }

}

export default ({...options}) => new ZettelStore({...options,api:api()})

import React from 'react'
import { observer } from 'mobx-react'

import { labelsStore } from 'sdc-i18n-stores'

export const ZettelName = observer(({zettel,...props}) =>
<span {...props}>
  {zettel?.name && <i className={'fa fa-fw fa-thumb-tack priority-'+(zettel?.priority || 0)}
  />} {zettel?.name || labelsStore.labelFor('zettel.unnamed')}
</span>)

import React from 'react'
import { observer      } from 'mobx-react'

import { editingMode   } from 'sdc-cms-writing'
import { Label         } from 'sdc-i18n-react'

import { Page          } from '../layout'

import { KastenList    } from './list'
import { KastenDetails } from './details'

export const Kasten = observer(({zettelStore}) => <Page>
  <h3 className="kasten">
    {editingMode.isEditMode.get() && <span className="toggle pull-right" onClick={editingMode.setMode('view')}><i className="fa fa-eye" /></span>}
    {editingMode.isViewMode.get() && <span className="toggle pull-right" onClick={editingMode.setMode('edit')}><i className="fa fa-edit" /></span>}
    <Label value="nav.kasten" />
  </h3>
  <KastenList />
  <KastenDetails />
</Page>)

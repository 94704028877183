import React from 'react'
import cn from 'classnames'
import { toJS         } from 'mobx'
import { inject, observer } from 'mobx-react'

import { Responsive, WidthProvider } from 'react-grid-layout'

import { DeleteButton } from 'sdc-react-basics'

import { mode         } from '../layout/mode'

import { ZettelName   } from './name'
import { ZettelText   } from './text'
import { nonPending   } from './helpers'

const ResponsiveGridLayout = WidthProvider(Responsive)

export const ZettelGrid = inject('zettelStore')(observer(({zettelStore}) => {

  if ( mode.state === 'full')   return null
  if (!zettelStore.selected.id) return null

  const onLayoutChange = (layout, oldItem, newItem, placeholder, e, element) => {
    zettelStore.layout(newItem)
  }

  const onItemDrop = (layout, item, e) => {
    item.i = e.dataTransfer.getData("text/plain")
    zettelStore.drop(item)
  }

  const idFromData = dataOrID => dataOrID.i || dataOrID

  const idToZettel = id => zettelStore.zettelByID[id]

  const idToZettelGrid = (dataOrID,i) => {
    const id = idFromData(dataOrID)
    const zettel = zettelStore.zettelByID[id]
    return dataOrID.i ? {
      grid   : dataOrID,
      zettel,
    } : {
      grid   : {
        x : 3 * (i % 4),
        y : 2 * Math.floor(i / 4),
        w : 3,
        h : 2,
      },
      zettel,
    }
  }

  const visibleOnGrid = data => data.zettel && (zettelStore.showHidden || !data.zettel.hidden)

  const linkToZettel = ({zettel,grid},i) => zettel ? <div className="text-center no-padding" key={zettel.id} data-grid={grid}>
    <div className={cn(
      'zettel-primary rgl',
      zettel.hidden && 'hidden',
    )}>
      <DeleteButton as="span" className="delete-button pull-right" onClick={zettelStore.unlink('refs',zettel?.id)} icon="times" />
      <span
          className="zettel-link"
          onClick={zettelStore.select(zettel)}>
        <ZettelName zettel={zettel} />
      </span>
    </div>
    {(zettel?.grid || zettel?.refs || []).filter(x => x).map(idToZettelGrid).filter(visibleOnGrid).map(({zettel: z}) => <div key={z.id}
        className="zettel-link zettel-secondary"
        onClick={zettelStore.select(z)}
    >
      <ZettelName zettel={z} />
    </div>)}
    <ZettelText zettel={zettel} />
  </div> : null

  return <ResponsiveGridLayout className="layout zettel-links zettel-verweise" cols={{lg: 12, md: 10, sm: 8}} rowHeight={80} margin={[8,8]} style={{border: 'none'}}
    onDragStop={onLayoutChange}
    onResizeStop={onLayoutChange}
    onDrop={onItemDrop} isDroppable droppingItem={{w:3, h:2, i:'drop-dead'}}
  >
    {(zettelStore.selected.grid || zettelStore.selected.refs || []).filter(nonPending).map(idToZettelGrid).filter(visibleOnGrid).map(linkToZettel)}
  </ResponsiveGridLayout>
}))

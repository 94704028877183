import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label     } from 'sdc-i18n-react'

import { zettelToPlainDraggable } from '../../zettel/helpers'

import { mode      } from '../mode'
import { Label4    } from '../../components'


export const RightMenu = inject('zettelStore')(observer(({zettelStore}) => {

  if (mode.state !== 'menu') return null;

  const visibleOnMenu = zettel => zettelStore.showHidden || !zettel.hidden

  return <div className="col-md-2 side-panel zettel-suche">
    {zettelStore.gepinnt.filter(visibleOnMenu).length > 0 && <Label4 value="zettels.pinned" />}
    {zettelStore.gepinnt.filter(visibleOnMenu).map(zettelToPlainDraggable(zettelStore))}
    {zettelStore.verwaist.filter(visibleOnMenu).length > 0 && <Label4 value="zettels.orphans" />}
    {zettelStore.verwaist.filter(visibleOnMenu).map(zettelToPlainDraggable(zettelStore))}
  </div>

}))

import React from 'react'
import cn from 'classnames'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

import _ from 'lodash'

import { Container, Row, Col } from 'reactstrap'

import { mode       } from '../layout/mode'
import { ui         } from '../design'

import { ZettelName } from './name'
import { nonPending } from './helpers'

export const ZettelParents = inject('zettelStore')(observer(({zettelStore}) => {

  if (mode.state === 'full') return null

  const idFromData = dataOrID => dataOrID.i || dataOrID

  const idToZettel = id => zettelStore.zettelByID[id]

  const idToZettelGrid = (dataOrID,i) => {
    const id = idFromData(dataOrID)
    const zettel = zettelStore.zettelByID[id]
    return dataOrID.i ? {
      grid   : dataOrID,
      zettel,
    } : {
      grid   : {
        x : 3*i,
        y : 0,
        w : 3,
        h : 2,
      },
      zettel,
    }
  }

  const zettelToLink = (self,rowSize) => zettel => <Col key={zettel.id} className="text-center zettel-parent" style={{
      flex: `0 0 0 ${100.0 * (zettel?.grid || zettel?.refs || []).length / rowSize}%`,
      maxWidth:   `${100.0 * (zettel?.grid || zettel?.refs || []).length / rowSize}%`
  }}>
    <Container fluid><Row>
    {zettelStore.parents(zettel).map(z => <Col key={z?.id || 'pending'}
          className={cn(
            z?.id === self.id && 'zettel-self',
            z?.id !== self.id && 'zettel-link',
            'zettel-secondary'
          )} onClick={z?.id !== self.id ? zettelStore.select(z) : undefined}>
      <ZettelName zettel={z} />
    </Col>)}
    </Row></Container>
    <div
        className="zettel-link zettel-primary"
        onClick={zettelStore.select(zettel)}>
      <ZettelName zettel={zettel} />
    </div>
    <Container fluid><Row>
    {(zettel.grid || zettel.refs || []).filter(nonPending).map(idToZettelGrid).map(({zettel: z}) => <Col key={z?.id || 'pending'}
          className={cn(
            z?.id === self.id && 'zettel-self',
            z?.id !== self.id && 'zettel-link',
            'zettel-secondary'
          )} onClick={z?.id !== self.id ? zettelStore.select(z) : undefined}>
      <ZettelName zettel={z} />
    </Col>)}
    </Row></Container>
  </Col>

  const parents = zettelStore.parents(zettelStore.selected)
  const rowSize = _.sumBy(parents, 'refs.length')

  return parents.length > 0 ? <Container fluid className="zettel-links zettel-parents">
    <Row>
      {parents.map(zettelToLink(zettelStore.selected,rowSize))}
    </Row>
  </Container> : null
}))

import   React              from 'react'
import { inject, observer } from 'mobx-react'

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import { userStore } from 'sdc-auth-user'
import { Label     } from 'sdc-i18n-react'

import { KastenName } from './name'

export const KastenMenu = inject('kastenStore')(observer(({kastenStore,nav}) => userStore.user.id ?
<UncontrolledDropdown>
  <DropdownToggle caret nav={!!nav} className="kasten-menu">
    <KastenName kasten={kastenStore.selected} />
  </DropdownToggle>
  <DropdownMenu>
    {kastenStore.dataList.filter(k => k.id).map(kasten =>
      <DropdownItem key={kasten.id}
          className="kasten-menu-entry"
          active={kasten.id === kastenStore.selected.id}
          onClick={kastenStore.select(kasten)}>
        <div className="clearfix">
          {kasten.size && <span className="details pull-right">({kasten.size} <Label value="nav.zettel" />)</span>}
          <KastenName className="kasten-name" kasten={kasten} />
        </div>
      </DropdownItem>
    )}
  </DropdownMenu>
</UncontrolledDropdown> : null))

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createKasten = /* GraphQL */ `
  mutation CreateKasten(
    $input: CreateKastenInput!
    $condition: ModelKastenConditionInput
  ) {
    createKasten(input: $input, condition: $condition) {
      id
      name
      owner
      size
      theme
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const updateKasten = /* GraphQL */ `
  mutation UpdateKasten(
    $input: UpdateKastenInput!
    $condition: ModelKastenConditionInput
  ) {
    updateKasten(input: $input, condition: $condition) {
      id
      name
      owner
      size
      theme
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const deleteKasten = /* GraphQL */ `
  mutation DeleteKasten(
    $input: DeleteKastenInput!
    $condition: ModelKastenConditionInput
  ) {
    deleteKasten(input: $input, condition: $condition) {
      id
      name
      owner
      size
      theme
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const createZettel = /* GraphQL */ `
  mutation CreateZettel(
    $input: CreateZettelInput!
    $condition: ModelZettelConditionInput
  ) {
    createZettel(input: $input, condition: $condition) {
      id
      kasten
      name
      text
      index
      priority
      hidden
      createdAt
      updatedAt
      lastViewed
      prev
      next
      refs
      grid {
        x
        y
        w
        h
        i
      }
    }
  }
`;
export const updateZettel = /* GraphQL */ `
  mutation UpdateZettel(
    $input: UpdateZettelInput!
    $condition: ModelZettelConditionInput
  ) {
    updateZettel(input: $input, condition: $condition) {
      id
      kasten
      name
      text
      index
      priority
      hidden
      createdAt
      updatedAt
      lastViewed
      prev
      next
      refs
      grid {
        x
        y
        w
        h
        i
      }
    }
  }
`;
export const deleteZettel = /* GraphQL */ `
  mutation DeleteZettel(
    $input: DeleteZettelInput!
    $condition: ModelZettelConditionInput
  ) {
    deleteZettel(input: $input, condition: $condition) {
      id
      kasten
      name
      text
      index
      priority
      hidden
      createdAt
      updatedAt
      lastViewed
      prev
      next
      refs
      grid {
        x
        y
        w
        h
        i
      }
    }
  }
`;

import { toJS } from 'mobx'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

export default () => ({
  create : entry  => () => API.graphql(graphqlOperation(mutations.createZettel, { input : toJS(entry) })),
  list   : params => () => {},
  update : delta  => () => API.graphql(graphqlOperation(mutations.updateZettel, { input : toJS(delta) })),
  delete : entry  => () => {},
})

import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { Row, Col, Input  } from 'reactstrap'

import { Label            } from 'sdc-i18n-react'
import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingType      } from 'sdc-cms-writing'

import { ThemeButton      } from './theme-button'


const shades = ['dark','light']
const hues   = ['Gray','Blue','Pink','Red','Orange','Green','Cyan']


export const KastenDetails = inject('kastenStore')(observer(({kastenStore}) => {

    if (editingType.typeID !== kastenStore.typeID) return null

    const data = {
    store     : kastenStore,
    dataStore : kastenStore,
    update    : kastenStore.update,
  }

  return <Provider {...data}><React.Fragment>
    <EntryFieldsList className="kasten-details" type="kasten" fields={['name']} />
    {shades.map(shade =>
      <Row className="kasten-details" key={shade}>
        <label className="col-md-2">
          <Label className="col-form-label" value={'kasten.theme.'+shade} />
        </label>
        <Col md={10}>
          {hues.map(theme => <ThemeButton key={shade+theme} shade={shade} theme={theme} />)}
        </Col>
      </Row>
    )}
  </React.Fragment></Provider>
}))

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateKasten = /* GraphQL */ `
  subscription OnCreateKasten($owner: String!) {
    onCreateKasten(owner: $owner) {
      id
      name
      owner
      size
      theme
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const onUpdateKasten = /* GraphQL */ `
  subscription OnUpdateKasten($owner: String!) {
    onUpdateKasten(owner: $owner) {
      id
      name
      owner
      size
      theme
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const onDeleteKasten = /* GraphQL */ `
  subscription OnDeleteKasten($owner: String!) {
    onDeleteKasten(owner: $owner) {
      id
      name
      owner
      size
      theme
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const onCreateZettel = /* GraphQL */ `
  subscription OnCreateZettel {
    onCreateZettel {
      id
      kasten
      name
      text
      index
      priority
      hidden
      createdAt
      updatedAt
      lastViewed
      prev
      next
      refs
      grid {
        x
        y
        w
        h
        i
      }
    }
  }
`;
export const onUpdateZettel = /* GraphQL */ `
  subscription OnUpdateZettel {
    onUpdateZettel {
      id
      kasten
      name
      text
      index
      priority
      hidden
      createdAt
      updatedAt
      lastViewed
      prev
      next
      refs
      grid {
        x
        y
        w
        h
        i
      }
    }
  }
`;
export const onDeleteZettel = /* GraphQL */ `
  subscription OnDeleteZettel {
    onDeleteZettel {
      id
      kasten
      name
      text
      index
      priority
      hidden
      createdAt
      updatedAt
      lastViewed
      prev
      next
      refs
      grid {
        x
        y
        w
        h
        i
      }
    }
  }
`;

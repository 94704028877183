import   React           from 'react'
import   cn              from 'classnames'
import { inject, observer } from 'mobx-react'

import { editingMode   } from 'sdc-cms-writing'
import { Label         } from 'sdc-i18n-react'
import { CreateButton, Button } from 'sdc-react-basics'

import { ui            } from '../design'
import { Page          } from '../layout'
import { ModeButton    } from '../layout/mode/button'
import { H3            } from '../components'

import { ZettelParents } from './parents'
import { ZettelLinks   } from './links'
import { ZettelName    } from './name'
import { ZettelDetails } from './details'
import { ZettelGrid    } from './grid'

export const Zettel = inject('kastenStore','zettelStore')(observer(({kastenStore,zettelStore}) => <Page>
  <ZettelParents />
  {kastenStore.selected.id && <H3 className="zettel">
    <ModeButton   className="float-left" state="menu"  />
    <ModeButton   className="float-left" state="links" />
    <ModeButton   className="float-left" state="full"  />
    <span onClick={zettelStore.cyclePriority} className="cycle-priority">
      <ZettelName zettel={zettelStore.selected} />
    </span>
    {editingMode.isEditMode.get() && <span className="toggle" onClick={editingMode.setMode('view')}><i className="fa fa-eye" /></span>}
    {editingMode.isViewMode.get() && zettelStore.selected.id && <span className="toggle" onClick={editingMode.setMode('edit')}><i className="fa fa-edit" /></span>}
    <Button as="span" icon="eye-slash" title="toggle.visibility" className={cn(
      zettelStore.selected.hidden && 'hidden',
      !zettelStore.selected.hidden && 'visible',
    )} onClick={zettelStore.toggleVisibility} />
    <CreateButton as="span" className="float-right action-create" icon="arrow-right" onClick={zettelStore.createFolgeZettel} title="zettel.create" />
    <CreateButton as="span" className="float-right action-create" icon="arrow-down"  onClick={zettelStore.createUnterZettel} title="zettel.create" />
    <CreateButton as="span" className="float-right action-create" onClick={zettelStore.createZettel} title="zettel.create" />
  </H3>}
  <ZettelLinks />
  <ZettelDetails />
  <ZettelGrid />
</Page>))

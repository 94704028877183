import React from 'react'
import cn    from 'classnames'
import { inject, observer } from 'mobx-react'

import { Auth          } from 'aws-amplify'

import { Link, NavLink } from 'react-router-dom'
import { Nav, Input, InputGroup, InputGroupAddon } from 'reactstrap'


import { userStore     } from 'sdc-auth-user'
import { UserName      } from 'sdc-auth-react'

import { AppVersion, Button   } from 'sdc-react-basics'
import { Label, LanguagesMenu } from 'sdc-i18n-react'


import { uiBase           } from '../env/constants'

import { mode             } from '../layout/mode'

import { KastenMenu       } from '../kasten/menu'


export const NavBar = inject('zettelStore')(observer(({zettelStore}) => mode.state === 'menu' ?
<nav className="navbar navbar-expand-lg navbar-static-top">

  <div className="navbar-header">
    <Link className="navbar-brand" to={uiBase}>
      <span><i className="fa fa-fw fa-archive" /></span>
      <Label className="app-name" value="app.name" />
    </Link>
  </div>

  <ul className={cn('nav navbar-nav', !userStore.user.id && 'mr-auto')}>
    <li className="nav-item">
      <NavLink className="nav-link" to="/kasten">
        <i className={'fa fa-archive'}></i> <Label value="nav.kasten" />
      </NavLink>
    </li>
    <KastenMenu nav />
    <li className="nav-item">
      <NavLink className="nav-link" to="/zettel">
        <i className={'fa fa-files-o'}></i> {zettelStore.dataList.length} <Label value="nav.zettel" />
      </NavLink>
    </li>
  </ul>

  {userStore.user.id && <InputGroup className="mr-auto zettel-suche">
    <InputGroupAddon addonType="prepend">
      <Button icon="eye-slash" title="toggle.hidden" className={cn(
      zettelStore.showHidden && 'hidden',
      !zettelStore.showHidden && 'visible',
    )} onClick={zettelStore.toggleVisibilityFilter} />
    </InputGroupAddon>
    <Input value={zettelStore.suche}
      onChange={zettelStore.filter} placeholder="Suche..."
    />
    <InputGroupAddon addonType="append">
      <Button icon="times" title="search.clear" disabled={!zettelStore.suche} onClick={zettelStore.clearSearch} />
    </InputGroupAddon>
  </InputGroup>}

  <AppVersion />

  <ul className="nav navbar-nav">
    <li className="nav-item"><NavLink className="nav-link" to={'usersURL'}><UserName /></NavLink></li>
    <LanguagesMenu nav />
    <li className="nav-item"><a className="nav-link" href="https://docs.zettelkasten.ninja" target="_new"><i className="fa fa-question-circle-o" /> <Label value="sites.docs" /></a></li>
    {!userStore.user.id && <li className="nav-item"><Button className="nav-link" caption="action.login" onClick={() => Auth.federatedSignIn({provider: 'Google'})} /></li>}
    { userStore.user.id && <li className="nav-item"><Button className="nav-link"    icon="power-off"    onClick={() => Auth.signOut()} /></li>}
  </ul>

</nav>
: null))

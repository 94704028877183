/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://2mezmmdqkzdpfd47kei3jhmhaq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:24a5ac6d-cf11-4a1b-b895-aba193fa51cf",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_33gvgl83q",
    "aws_user_pools_web_client_id": "2is9nmvogr7hfh5bst4be0ns5s",
    "oauth": {
        "domain": "oauth-default.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000,https://www.zettelkasten.ninja",
        "redirectSignOut": "http://localhost:3000,https://www.zettelkasten.ninja",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;

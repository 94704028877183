import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

import _ from 'lodash'

import { Draggable, Droppable } from 'react-beautiful-dnd'

import { Container, Row, Col  } from 'reactstrap'

import { DeleteButton } from 'sdc-react-basics'

import { mode         } from '../layout/mode'

import { ZettelName   } from './name'

const getItemStyle = (isDragging,draggableStyle) => ({
  userSelect : 'none',
  padding    : '.125rem .25rem',
  flexBasis  : '0',
  flexGrow   : '1',
  maxWidth   : '100%',
  ...draggableStyle,
})

const getListStyle = isDraggingOver => ({
  display    : 'flex',
  width      : '100%',
})

export const ZettelLinks = inject('zettelStore')(observer(({zettelStore}) => {

  if ( mode.state === 'full')   return null
  if (!zettelStore.selected.id) return null

  const prev = zettelStore.selected.prev || []
  const next = zettelStore.selected.next || []

  const idToZettel = id => zettelStore.zettelByID[id]

  const linkToZettel = arrow => (zettel,index) =>
  <Draggable key={zettel?.id || index} draggableId={''+(zettel?.id || index)} index={index}>
    {(provided,snapshot) => <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(
          snapshot.isDragging,
          provided.draggableProps.style
        )}
      ><div
          className="zettel-link zettel-primary clearfix"
          onClick={zettelStore.select(zettel)}>
        {arrow === 'append'  && <DeleteButton as="span" className="delete-button pull-left"  onClick={zettelStore.unlink('prev',zettel.id)} icon="times" />}
        {arrow === 'prepend' && <span>→ </span>}
        {arrow === 'prepend' && <DeleteButton as="span" className="delete-button pull-right" onClick={zettelStore.unlink('next',zettel.id)} icon="times" />}
        {arrow === 'append'  && <span> ←</span>}
        <ZettelName zettel={zettel} />
      </div>
      {(zettel.refs || []).map(idToZettel).map(z => <div key={z.id} className="zettel-link zettel-secondary" onClick={zettelStore.select(z)}>
        <ZettelName zettel={z} />
      </div>)}
    </div>}
  </Draggable>

  return <Container fluid className="zettel-links">
    <Row>
      <Col className="text-right">
        <Droppable key={zettelStore.selected.id} droppableId="prev">
          {(provided,snapshot) => <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}>
            {prev.map(idToZettel).map(linkToZettel('append'))}
            {provided.placeholder}
          </div>}
        </Droppable>
      </Col>
      <Col>
        <Droppable key={zettelStore.selected.id} droppableId="next">
          {(provided,snapshot) => <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}>
            {next.map(idToZettel).map(linkToZettel('prepend'))}
            {provided.placeholder}
          </div>}
        </Droppable>
      </Col>
    </Row>
  </Container>
}))

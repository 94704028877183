/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getKasten = /* GraphQL */ `
  query GetKasten($id: ID!) {
    getKasten(id: $id) {
      id
      name
      owner
      size
      theme
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const listKastens = /* GraphQL */ `
  query ListKastens(
    $filter: ModelKastenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKastens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        size
        theme
        createdAt
        updatedAt
        lastUsed
      }
      nextToken
    }
  }
`;
export const getZettel = /* GraphQL */ `
  query GetZettel($id: ID!) {
    getZettel(id: $id) {
      id
      kasten
      name
      text
      index
      priority
      hidden
      createdAt
      updatedAt
      lastViewed
      prev
      next
      refs
      grid {
        x
        y
        w
        h
        i
      }
    }
  }
`;
export const listZettels = /* GraphQL */ `
  query ListZettels(
    $filter: ModelZettelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZettels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        kasten
        name
        text
        index
        priority
        hidden
        createdAt
        updatedAt
        lastViewed
        prev
        next
        refs
        grid {
          x
          y
          w
          h
          i
        }
      }
      nextToken
    }
  }
`;

import React from 'react'
import { inject, observer } from 'mobx-react'

import { Droppable } from 'react-beautiful-dnd'

import { Label     } from 'sdc-i18n-react'

import { zettelToPlainDraggable } from '../../zettel/helpers'

import { mode       } from '../mode'
import { Label4     } from '../../components'

export const LeftMenu = inject('zettelStore')(observer(({zettelStore}) => {

  if (mode.state !== 'menu') return null;

  const visibleOnMenu = zettel => zettelStore.showHidden || !zettel.hidden

  return <div className="col-md-2 side-panel left-side zettel-suche">
    {zettelStore.filtered.filter(visibleOnMenu).length > 0 && <Label4 value="zettels.results" />}
    <Droppable droppableId="filtered" key={zettelStore.suche+'-res-'+zettelStore.selected.id}>
      {(provided,snapshot) => <div
          ref={provided.innerRef}>
        {zettelStore.filtered.filter(visibleOnMenu).map(zettelToPlainDraggable(zettelStore,'filtered'))}
        {provided.placeholder}
      </div>}
    </Droppable>
    {zettelStore.recently.filter(visibleOnMenu).length > 0 && <Label4 value="zettels.last" />}
    <Droppable droppableId="recently" key={'rec'+zettelStore.selected.id}>
      {(provided,snapshot) => <div
          ref={provided.innerRef}>
        {zettelStore.recently.filter(visibleOnMenu).map(zettelToPlainDraggable(zettelStore,'recently'))}
        {provided.placeholder}
      </div>}
    </Droppable>
    <Label4 value="Newsletter" />
    <span>Klicken Sie <a
      href="http://eepurl.com/drUchf" target="_blank" rel="noopener noreferrer">
      hier,
    </a> um sich für unseren Newsletter anzumelden</span>
  </div>

}))
